/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState } from 'react';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "pages/account/components/FormField";
import { useUser } from "context/UserContext";
import axios, {AxiosError} from 'axios';
import SoftAlert from "components/SoftAlert";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [successfulReset, setSuccessfulReset] = useState(false);

  const user = useUser();

  const validateField = (fieldName, field, setError) => {
      if (field.length < 1) {
          setError(`${fieldName} is required`);
          return false;
      } else {
          setError('');
          return true;
      }
  };

  const resetClick = async () => {
    if (validateField('Old password', oldPassword, setOldPasswordError) &&
    validateField('New password', newPassword, setNewPasswordError) &&
    validateField('Confirm password', confirmPassword, setConfirmPasswordError)) {
      try {
          if(newPassword != confirmPassword){
              setConfirmPasswordError('Confirm does not match new password');
          }
          else if(oldPassword === newPassword){
              setConfirmPasswordError('New password cannot be the same as old password')
          }
          else if(user && newPassword === confirmPassword){
              const { data } = await axios.post(
                  'users/reset-password',
                  {
                      userId: user.id,
                      oldPassword,
                      newPassword,
                  },
                  { withCredentials: true }
              );

              if (!data) {
                  setOldPasswordError("Invalid Password");
              }
              else{
                  setOldPasswordError('');
                  setNewPasswordError('');
                  setConfirmPasswordError('');
                  setNewPassword('');
                  setConfirmPassword('');
                  setOldPassword('');
                  setSuccessfulReset(true);              
              }
          }
        } catch (error) {
            if( error instanceof AxiosError){
                console.error(error);
            }
        }
    }
  };

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          resetClick();
      }
  };

  return (
    <>
      {successfulReset && (<SoftAlert dismissible>Password Successfully Reset!</SoftAlert>)}
      <Card id="change-password">      
        <SoftBox p={3}>
          <SoftTypography variant="h5">Change Password</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                label="current password"              
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(event) => setOldPassword(event.currentTarget.value)}
                error={oldPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="new password"
                placeholder="New Password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.currentTarget.value)}
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                error={newPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="confirm new password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.currentTarget.value)}
                inputProps={{ type: "password", autoComplete: "" }}
                onKeyDown={handleKeyDown}
                error={confirmPasswordError}
              />
            </Grid>
          </Grid>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            marginTop="20px"
          >
            <SoftBox ml="auto">
              <SoftButton variant="gradient" color="dark" size="small" onClick={resetClick}>
                update password
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>      
      </Card>
    </>
  );
}

export default ChangePassword;
