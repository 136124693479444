/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components


// @mui material components
import { useState } from 'react';
import Card from "@mui/material/Card";


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "./components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

import axios, {AxiosError} from 'axios';
import { useUserUpdate } from "../../context/UserContext";

function Basic() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');  
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const userUpdater = useUserUpdate()

  const validateEmail = (email) => {
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
          setEmailError('Please enter a valid email');
          return false;
      } else {
          setEmailError('');
          return true;
      }
  };

  const validatePassword = (password) => {
      if (password.length < 1) {
          setPasswordError('Invalid Password');
          return false;
      } else {
          setPasswordError('');
          return true;
      }
  };

  const loginClick = async () => {
      if (validateEmail(username) && validatePassword(password)) {
          try {
              const res = await axios.post(
                  'users/login',
                  {
                      email: username,
                      password: password
                  },
                  { withCredentials: true }
              );
              userUpdater && userUpdater({
                  type: 'login',
                  user: {
                    id: res.data.userId,
                    name: res.data.name,
                    email: res.data.email,
                  }
              })
          } catch (error) {
              if( error instanceof AxiosError){
                  if(error?.response?.status == 401){
                      setPasswordError("Invalid Login");
                  }
                  else{
                      console.log(error.message)
                  }
              }
          }
      }
  };

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          loginClick();
      }
  };

  return (
    <BasicLayout
      title="Welcome!"      
      image={curved9}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Sign in
          </SoftTypography>
        </SoftBox>
        <SoftBox p={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput 
                placeholder="Username"
                value={username}
                onChange={(event) => setUsername(event.currentTarget.value)}
                onKeyDown={handleKeyDown}                
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput 
                placeholder="Password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}                
                onKeyDown={handleKeyDown}                
              />
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="info" fullWidth onClick={loginClick}>
                sign in
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
