/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";

export default styled(Popover)(() => {
  return {
    "& .MuiPopover-paper": {
      backgroundColor: '#ffffff !important',
    },
  };
});
