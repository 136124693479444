/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardNavbar from "components/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import JsonView from 'react18-json-view';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ToolRunForm from "../../components/ToolRunForm";

import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

import CircularProgress from '@mui/material/CircularProgress';

import {useParams} from "react-router-dom";
import axios from 'axios';
import SoftButton from "components/SoftButton";

function TestUI() {
  const [isRedditFlow, setIsRedditFlow] = useState(false);

  const {toolId} = useParams();
  const [tool, setTool] = useState({});
  const [fileContents, setFileContents] = useState({});
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    if(toolId) {
        (async function() {
            const tool = await axios.get(`tools/${toolId}`);            
            setTool(tool.data);
            setIsRedditFlow(!!tool.data.reddit_flow);
        })();
    }
  }, [toolId]);
  
  useEffect(() => {
    (async function() {
        const flowRuns = await axios.get('flow_tester/blobs/1');       
        setFiles(flowRuns.data);
    })();
  }, []);



  const toolRunFormOnSubmitQueueRun = async (data) => {
    setLoading(true);
    console.log(data);
    let vars = JSON.parse(data['variables']);
    let postData = {
      "flow_path":"/flows/alexeckensweiler/flows/Reply",
      "flow_id": data.flowId,
      "inputs": vars
    }
    try{
      const res = await axios.post('https://web-aca-app.grayfield-2e1ce638.westus2.azurecontainerapps.io/flow', postData)      
      setResponse(res.data);
      console.log(res);
    }
    catch(error)
    {
      console.log(error);
      setResponse({"error":error.message});
    }
    
    setLoading(false);
  }
  
  function getContent() {  
    return (
      <Grid item xs={12} sm={12} md={11} lg={10} xl={8} xxl={8}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={2}>
            <SoftBox pl={1} position="absolute" top="25px" right="32px">
              <SoftTypography variant="Overline" color="secondary">   
                  <Link href="/tools">
                    <Icon>close</Icon>
                  </Link>
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <ToolRunForm 
                  flowId={tool?.flow_id ?? 1}
                  isRedditFlow={isRedditFlow}
                  onSubmit={toolRunFormOnSubmitQueueRun}
                />
            </SoftBox>
          </SoftBox>
        </Card>
      </Grid>
    );
  }

  const onChange = (event, fileName) => {
    console.log(event.target.value);
    console.log(fileName);
    let fileContent = {...fileContents};
    fileContent[fileName] = event.target.value;
    console.log(fileContent);
    setFileContents(fileContent);
  };

  const saveFileChanges = async () => {
    console.log("SAVINGS!")

    const data = {
      fileContents: fileContents
    }        
    const res = await axios.post('/flow_tester/blobs/1', data);
    console.log(res);
  }

  function getEditors() {
    const editors = [];

    for (const [fileName, fileContent] of Object.entries(files)) {
      const rows = Math.max(5, Math.ceil(fileContent.length / 250));
      editors.push(
          <Grid item xs={12} sm={12} md={12} key={`${fileName}`}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                  <SoftBox mt={1} mb={1} lineHeight={0} display="inline-block">
                      <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                      >
                          {fileName}
                      </SoftTypography>
                      <SoftInput defaultValue={fileContent} onChange={(e) => onChange(e, fileName)} multiline rows={rows} />
                  </SoftBox>
              </SoftBox>
          </Grid>
      );
    }

    return editors;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title={tool.name} />
      <SoftBox mt={1} mb={20}>
        <Grid container spacing={2}>
          {getEditors()}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <SoftBox mt={3} mb={3} width="100%" display="flex" justifyContent="center">
              <SoftButton variant="contained" color="dark" onClick={saveFileChanges}>
                Save
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {getContent()}
        </Grid>
        {loading && 
          <Grid container justifyContent="center">
            <CircularProgress color="inherit" />
          </Grid>
        }
        {loading === false && response &&
          <Grid container justifyContent="center">
            <JsonView src={response} theme={'default'} />
          </Grid>
        }
      </SoftBox>      
    </DashboardLayout>
  );
}

export default TestUI;
