import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useUser } from '../../context/UserContext';
import DataTable from "../Tables/DataTable";
import SoftButton from "../SoftButton";
import SoftBox from '../SoftBox';
import SoftTypography from '../SoftTypography';
import PropTypes from "prop-types";
import RunnerModal from 'components/RunnerModal';

function ScholarlyURLSelector({ flowId, urls }) {
    const [data, setData] = useState(undefined);
    const [selectButtons, setSelectButtons] = useState([]);
    const [selectedPost, setSelectedPost] = useState();

    const [runPostIds, setRunPostIds] = useState([]);
    
    const [flowRunnerOpen, setFlowRunnerOpen] = useState(false);

    const user = useUser()

    const columns = [
        { 
            accessorKey: 'url',
            header: 'URL',
            cell: ({ cell, row }) => {
                return <>{row.original.url}</>
            },
         },
        { accessorKey: 'rating', header: 'Rating' },
        { 
            accessorKey: 'flow_button',
            header: 'Select',
            cell: ({ cell, row }) => {
                return <>{row.original.flow_button}</>
            },
        },
    ];

    const handleSelectPost = (postId) => {
        setSelectedPost(postId);
        setFlowRunnerOpen(true);
    }

    const onRunnerModalSubmit = () => {
        let updatedRunPostIds = runPostIds;
        updatedRunPostIds.push(selectedPost);
        setRunPostIds(updatedRunPostIds);
        setFlowRunnerOpen(false);
        setSelectedPost(null);
    }

    const onRunnerModalCancel = () => {
        setFlowRunnerOpen(false);
        setSelectedPost(null);
    }
    
    useEffect(() => {
        if(urls) {
            (async function() {
                const postsButtons = {};
                for(const row of urls) {
                    postsButtons[row.url] = { disabled: false };
                }
                setSelectButtons(postsButtons);
            })();
        }
    }, [urls])

    useEffect(() => {
        if(urls && selectButtons) {
            (async function() {
                const formattedData = [];
                for(const url of urls) {
                    const postButton =
                        <SoftButton 
                            variant="gradient" 
                            color="primary"
                            onClick={() => {handleSelectPost(url.url)}} 
                            className={'flow-run-submit-button'}
                            sx={{ width: 128 }}
                            disabled={runPostIds.includes(url.url)} 
                            endIcon={runPostIds.includes(url.url) && <CheckIcon size={18} />} 
                        >
                             Run
                        </SoftButton>

                    const titleLink = 
                        <SoftTypography 
                            variant="caption" 
                            component="a" 
                            target="_blank" 
                            href={url.url} 
                            fontWeight="medium" 
                            color="primary"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {url.url.length > 120 ? url.url.substring(0,120) + "..." : url.url}
                            <OpenInNewIcon sx={{ marginLeft: "5px"}}/>
                        </SoftTypography>

                    formattedData.push({
                        url: titleLink,
                        flow_button: postButton,
                        rating: url.rating ?? null,
                    })
                }
                setData(formattedData);
            })();
        }
    }, [user, selectButtons, flowId, selectedPost, runPostIds])

    return (
        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <RunnerModal
                flowId={flowId}
                open={flowRunnerOpen}
                onClose={onRunnerModalCancel}
                onSubmit={onRunnerModalSubmit}
                initialValuesOverride={{'url': selectedPost}}
            />
            {data && (
            <>
                <DataTable
                    table={{
                        rows: data ?? [],
                        columns: columns,
                    }}
                    canSearch={false}      
                    canAdjustPageSize={false}              
                />
            </>)}
        </SoftBox>
    )
}

ScholarlyURLSelector.propTypes = {
    flowId: PropTypes.number.isRequired,
    urls: PropTypes.array,
}

export default ScholarlyURLSelector;