/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import SoftButton from "../../components/SoftButton";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftDropzone from "components/SoftDropzone";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

// NewProduct page components
import { useUser } from 'context/UserContext';
import {useParams} from "react-router-dom";
import axios from 'axios';
import Papa from 'papaparse'
import Card from "@mui/material/Card";
import { CSVLink } from 'react-csv';
import SoftTypography from "components/SoftTypography";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import SoftAlert from "components/SoftAlert";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

function BatchRunUI() {
  const {toolId} = useParams();
  const [tool, setTool] = useState({});
  const [inputs, setInputs] = useState([])
  const [modalContent, setModalContent] = useState('')
  const [open, setOpen] = useState(false);
  const [expectedParameters, setExpectedParameters] = useState(null);
  const [csvTemplate, setCsvTemplate] = useState(null);
  const [csvError, setCsvError] = useState(null);
  const [csvFilename, setCsvFilename] = useState(null);
  const [runButtonColor, setRunButtonColor] = useState('success');
  const [runButtonContent, setRunButtonContent] = useState('Run');
  const [lastRunId, setLastRunId] = useState(null);

  const user = useUser()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(toolId) {
        (async function() {
            const tool = await axios.get(`tools/${toolId}`);            
            const flowParametersResponse = await axios.get(`flows/parameters/${tool.data.flow_id}`);
            const defaultRow = [user?.email ?? ''];
            const headers = ['email'];
            for(const parameter of flowParametersResponse.data) {
              headers.push(parameter.name);
              if(parameter.name == 'email') {
                defaultRow.push(user?.email ?? (parameter.default_value == null ? '' : parameter.default_value))  
              } else {
                defaultRow.push(parameter.default_value == null ? '' : parameter.default_value)
              }              
            }
            let filename = tool.data.name.toLowerCase().replace(/ /g,"_") + "_batch_run_template.csv";
            setCsvTemplate([headers, defaultRow])
            setCsvFilename(filename);
            setExpectedParameters(flowParametersResponse.data);
            setTool(tool.data);
        })();
    }
  }, [toolId]);  
  
  const openModal = (content) => {    
    handleOpen();
    setModalContent(content)    
  }

  const closeModal = () => {
    handleClose();
    setModalContent('');    
  }

  const processFile = (file, done) => {    
    if(file.name.endsWith('.csv')) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log(results)
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });
          const missingParams = []
          for(const parameter of expectedParameters) {          
            if(parameter.is_optional != 1 && !rowsArray[0].includes(parameter.name)) missingParams.push(parameter.name)
          }
          if(missingParams.length > 0) {
            setInputs([]);
            setCsvError(`CSV Missing Parameter(s): ${missingParams.join(', ')}`);
          } else {
            setCsvError(null);
            setInputs(results.data);
          }
          done();
        },
      });
    } else {
      setCsvError(`Only CSV files are accepted.`);
    }
  }

  const runFlow = async () => {
    setRunButtonColor('secondary');
    setRunButtonContent('Running');
    const promises = [];
    const batchRunId = await axios.get('flows/batchRunId');
    for (const input of inputs) {
      promises.push(new Promise((resolve, reject) => {
        const tempParameters = { ...input }
        // set email to logged in user email
        if(!tempParameters['email'] && user) tempParameters['email'] = user.email;        
        const data = {
          userId: user?.id,
          flowId: tool.flow_id,
          variables: JSON.stringify(tempParameters),
          batchRunId: batchRunId.data?.batchRunId,
        }
        axios.post('/flows/run', data).then((data) => resolve(data));
      }))
    }
    Promise.all(promises).then((values) => {
      setRunButtonColor('success');
      setRunButtonContent('View');
      let highestId = values[0].data.insertId;
      for(const value of values) {
        if (value.data.insertId > highestId) highestId = value.data.insertId
      }
      setLastRunId(highestId);
    })
  }

  const fileDropZone = useMemo(() => <SoftDropzone options={{ addRemoveLinks: true, maxFiles: 1, accept: function(file, done) { processFile(file, done) } }} />, [expectedParameters]);

  return tool.name ? (  
    <DashboardLayout>
      <DashboardNavbar title={`${tool.name} Batch Run`} />
      <Modal open={open} onClose={() => closeModal()} aria-labelledby={'Inputs'}>
        <Box sx={style}>
            {modalContent}
        </Box>
      </Modal>
      <Grid item xs={12} sm={12} md={11} lg={10} xl={8} xxl={8}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox pl={1} position="absolute" top="25px" right="32px">
                <SoftTypography variant="Overline" color="secondary">   
                    <Link href="/tools">
                      <Icon>close</Icon>
                    </Link>
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={1} mb={20}>
                <Grid container justifyContent="center" pt={5}>
                  <Grid textAlign="center" item xs={12} lg={12} pt={1}>
                    {csvError ? 
                      <SoftAlert color="error" dismissible>                   
                        <Icon fontSize="medium">error</Icon>&nbsp;
                        <SoftTypography variant="body2" color="white">
                          {csvError}
                        </SoftTypography>
                      </SoftAlert>
                    : null}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    {fileDropZone}
                  </Grid>
                </Grid>                
                <Grid container justifyContent="space-between" pt={5}>
                  {csvTemplate ? 
                    <Grid item xs={12} lg={2}>
                      <CSVLink 
                        data={csvTemplate}
                        filename={csvFilename}
                        > 
                        <SoftButton>Export Template</SoftButton>
                      </CSVLink>
                    </Grid>
                  : null}                                                   
                  <Grid container item xs={12} lg={4} justifyContent="flex-end">
                    <SoftButton onClick={() => openModal(<JsonView src={inputs} theme={'default'} />, 'Outputs')} style={{ marginRight: 10 }} disabled={inputs.length <= 0}>Inputs</SoftButton>
                    <SoftButton onClick={() => { runButtonContent == 'View' ? navigate(`/results/${lastRunId}`) : runFlow()}} disabled={inputs.length <= 0} color={runButtonColor}>{runButtonContent}</SoftButton>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>            
          </Card>
        </Grid>
    </DashboardLayout>
  ) : null;
}

export default BatchRunUI;
