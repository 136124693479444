/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { useNavigate } from "react-router-dom";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ color = "dark", image, title, lastRun, description, avgRunTime, toolId, dropdown = false }) {  
  const navigate = useNavigate();
  return (
    <Card>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}            
          />
          <SoftBox ml={2} lineHeight={0}>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium" onClick={() => {close; navigate(`/tool/${toolId}/run`)}} style={{ cursor: 'pointer' }}>
                {title}
              </SoftTypography>
            </SoftBox>            
          </SoftBox>
          {dropdown && (
            <SoftTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </SoftTypography>
          )}
          {dropdown.menu}
        </SoftBox>
        <SoftBox my={2} lineHeight={1} sx={{ minHeight: 69 }}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        <Divider />
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          {avgRunTime ? (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {avgRunTime}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Average Run Time
              </SoftTypography>
            </SoftBox>
          ) : null}
          {lastRun ? (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {lastRun.datetime}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary" onClick={() => navigate(`/results/${lastRun.runId}`)} sx={{ cursor: 'pointer' }}>
                Last Run
              </SoftTypography>
            </SoftBox>
          ) : null}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lastRun: PropTypes.object,
  description: PropTypes.node.isRequired,
  avgRunTime: PropTypes.string,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
  toolId: PropTypes.any,
};

export default ComplexProjectCard;
