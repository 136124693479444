/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { 
  useReactTable,  
  getCoreRowModel,  
  getPaginationRowModel, 
  getSortedRowModel,
  flexRender,
  getFilteredRowModel
} from '@tanstack/react-table'; 

import _ from 'lodash';
// @mui material components
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Tables/DataTable/DataTableBodyCell";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

function Filter({ column }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue)?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue)?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="w-36 border shadow rounded"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '')}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 250,
  ...props
}) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <SoftInput {...props} value={value} size={'small'} onChange={e => setValue(e.target.value)} />
  )
}

function DataTable({  
  canSearch = false,
  table,  
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder = false,
  isLoading = false,
  canAdjustPageSize = true,
  initialState = { pagination: { pageIndex: 0, pageSize: 10 } },  
  initialColumnFilters = []
}) {  
  const [columnFilters, setColumnFilters] = useState(initialColumnFilters)
  const [showColumnFilters, setShowColumnFilters] = useState(initialColumnFilters.length > 0)
  const [sorting, setSorting] = useState(initialState.sorting ?? []);
  const pageSizes = [5, 10, 15, 20, 25];  
  const data = useMemo(() => table.rows, [table]);  
  const columns = useMemo(
    () =>
      isLoading
        ? table.columns.map((column) => ({
            ...column,
            cell: () => <Skeleton width={column.size ?? 100} />,
          }))
        : table.columns,
    [isLoading, table]
  );

  const tableInstance = useReactTable({
    columns, 
    data,
    initialState:initialState,    
    enableGlobalFilter: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),    
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),    
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters, sorting
    }
  });
  
  const {  
    nextPage,
    previousPage,
    setPageIndex,
    setPageSize,
    setGlobalFilter,    
  } = tableInstance;

  const { pageIndex, pageSize } = tableInstance.getState().pagination
  const { globalFilter } = tableInstance.getState()
  const pageOptions = tableInstance.getPageOptions();
  const headerGroups = tableInstance.getHeaderGroups();
  const page = tableInstance.getPaginationRowModel().rows;
  const totalRows = tableInstance.getRowCount();
  const canPreviousPage = tableInstance.getCanPreviousPage();
  const canNextPage = tableInstance.getCanNextPage();
  
  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => setPageIndex(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);
  
  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? setPageIndex(0) : setPageIndex(Number(value));


  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => setPageIndex(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  
  // Search input state handle
  const onSearchChange = (value) => {
    setGlobalFilter(value || undefined);    
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {pageSize || canSearch ? (
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {canAdjustPageSize && (
            <SoftBox display="flex" alignItems="center">
              <SoftSelect
                defaultValue={{ value: pageSize, label: pageSize }}
                options={pageSizes.map((entry) => ({ value: entry, label: entry }))}
                onChange={(e) => setPageSize(e.value)}
                size="small"
              />
              <SoftTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </SoftTypography>
            </SoftBox>
          )}
          {canSearch && (
            <SoftBox width="13rem" ml="auto" sx={{ display: 'flex', alignItems: 'center' }}>
              <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0, marginRight: '10px' }} onClick={() => {setShowColumnFilters(!showColumnFilters)}} >
                <Tooltip title="Filters" placement="top">
                  {showColumnFilters ? <FilterAltIcon /> : <FilterAltOffIcon />}
                </Tooltip>
              </SoftTypography>
              <SoftInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
      ) : null}
      <Table>
        <SoftBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key}>
              {headerGroup.headers.map((header, key) => (
                <DataTableHeadCell
                  key={key}               
                  width={header.getSize() ? header.getSize() : "auto"}
                  align={header.align ? header.align : "left"}
                  onClick={header.column.getToggleSortingHandler()}
                  sorted={header.column.getIsSorted()}
                  canSort={header.column.getCanSort()}
                >
                  {flexRender( 
                    header.column.columnDef.header, 
                    header.getContext() 
                  )}
                  {showColumnFilters ? (header.column.getCanFilter() ? (
                    <div style={{ marginTop: 5 }}>
                      <Filter column={header.column} />
                    </div>
                  ) : <div style={{ marginTop: 5, height: 32 }} />                  
                ) : null}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SoftBox>
        <TableBody>
          {page.map((row, key) => {            
            return (
              <TableRow key={key}>
                {row.getVisibleCells().map((cell, key) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && page.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}                    
                  >
                    {flexRender(
                      cell.column.columnDef.cell, 
                      cell.getContext() 
                    )}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(canPreviousPage || canNextPage) && (
        <SoftBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={pageOptions.length === 1 ? 0 : 3}
        >  
          <SoftBox mb={{ xs: 3, sm: 0 }}>
            <SoftTypography variant="button" color="secondary" fontWeight="regular">
              Showing {pageIndex * pageSize + 1} to {pageIndex * pageSize + pageSize} of {totalRows} entries
            </SoftTypography>
          </SoftBox>        
          {pageOptions.length > 1 && (
            <SoftPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage > 0 && (
                <SoftPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </SoftPagination>
              )}
              {renderPagination.length > 6 ? (
                <SoftBox width="5rem" mx={1}>
                  <SoftInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </SoftBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <SoftPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </SoftPagination>
              )}
            </SoftPagination>
          )}
        </SoftBox>
      )}
    </TableContainer>
  );
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  canSearch: PropTypes.bool,
  canAdjustPageSize: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  initialState: PropTypes.any,
  state: PropTypes.any,
  isLoading: PropTypes.bool,
  initialColumnFilters: PropTypes.array,
};

export default DataTable;
