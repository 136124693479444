import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ToolDocs() {
    const [tool, setTool] = useState(null);
    const { toolSlug } = useParams();

    useEffect(() => {
        (async function() {
            const toolResponse = await axios.get(`/tools/docs/${toolSlug}`);
            setTool(toolResponse?.data)
        })();
    }, [])

    return (
        <DashboardLayout>
            {tool && (
                <>
                    <DashboardNavbar title={tool[0].name ?? false}/>
                    {tool[0].long_description ? (
                        <>
                            <SoftTypography variant="h4" textTransform="capitalize" fontWeight="medium">
                                Description
                            </SoftTypography>
                            <SoftBox mb={2} lineHeight={1}>
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                    {tool[0].long_description}
                                </SoftTypography>
                            </SoftBox>
                            <SoftTypography variant="h4" textTransform="capitalize" fontWeight="medium">
                                Inputs
                            </SoftTypography>
                            {tool.map((toolInput) => (
                                <SoftBox lineHeight={1} key={toolInput.input_name}>
                                    <SoftTypography variant="button" fontWeight="700" color="text">
                                        {toolInput.input_name}{': '}
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        {toolInput.input_description}
                                    </SoftTypography>
                                </SoftBox>
                            ))}            
                        </>
                    ) : (
                        <>
                            No tool found.
                        </>
                    )}
                </>
            )}
        </DashboardLayout>
    )
}

export default ToolDocs;