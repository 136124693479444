import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ToolRunForm from '../ToolRunForm';
import PropTypes from "prop-types";
import axios from 'axios';

function RunnerModal({ open, flowId, previousRunId, initialValuesOverride, onSubmit, onClose, modalTitle = '' }) {

    const toolRunFormOnSubmit = async (data) => {
        onSubmit();
        await axios.post('/flows/run', data);
    }

    return (
        <Dialog 
            open={open} 
            onClose={() => onClose()} 
            size="auto" 
            title={modalTitle} 
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent>
                <ToolRunForm
                    flowId={flowId}
                    previousRunId={previousRunId}
                    initialValuesOverride={initialValuesOverride}
                    onSubmit={toolRunFormOnSubmit}
                />
            </DialogContent>
        </Dialog>
    )
}

RunnerModal.propTypes = {
    flowId: PropTypes.number,
    open: PropTypes.bool.isRequired,
    previousRunId: PropTypes.number,
    modalTitle: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValuesOverride: PropTypes.object,
}

export default RunnerModal;