/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "components/Configurator/ConfiguratorRoot";

import axios from 'axios';
import { useUser, useUserUpdate } from "../../context/UserContext";
import Popover from '@mui/material/Popover';
// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } =
    controller;
  const [disabled, setDisabled] = useState(false);  
  const handleConfiguratorOpen = (event) => setOpenConfigurator(dispatch, event ? event.currentTarget : null);  
  const navigate = useNavigate();
  const user = useUser();
  const userUpdater = useUserUpdate()
  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const id = 'simple-popover';

  const logoutClick = async () => {
    try {
      if (user){
        await axios.post(
            'users/logout',
            { id: user.id },
            { withCredentials: true }
        );
      }
    } catch (error) {
        console.log(error);
    }
    userUpdater && userUpdater({
      type: 'logout'
    })
  };

  return (
    <Popover 
        id={id}
        open={!!openConfigurator}
        anchorEl={openConfigurator}
        onClose={() => handleConfiguratorOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}                
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"        
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Settings</SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => handleConfiguratorOpen(null)}
        >
          close
        </Icon>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox mt={3} mb={2} mr={5}>
          <SoftBox mb={2}>
            <SoftButton
              component={Link}
              onClick={() => { handleConfiguratorOpen(null); navigate(`/account`) }}
              color="info"
              variant="gradient"
              fullWidth
            >
              account
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={3} mb={2}>
          <SoftBox mb={2}>
            <SoftButton
              component={Link}
              onClick={() => { handleConfiguratorOpen(null); logoutClick(); }}
              color="info"
              variant="gradient"
              fullWidth
            >
              sign out
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>      
    </Popover>
  );
}

export default Configurator;
