import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

function DownloaderUI() {
    const { type, folder, file } = useParams();

    useEffect(() => {
        (async function() {
            if(type && folder && file) {
                const res = await axios.get(`/download/${type}/${folder}/${file}`, { responseType: 'blob' });
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', file); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        })();
    }, [type, folder, file])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            You should get a popup.
        </DashboardLayout>
    )
}

export default DownloaderUI;