import { createContext, useContext, useReducer } from 'react';
import PropTypes from "prop-types";

const UserContext = createContext(null);

const UserUpdateContext = createContext(null);

export function UserProvider({ children }) {
  const [user, dispatch] = useReducer(
    userReducer,
    null
  );

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={dispatch}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

export function useUserUpdate() {
  return useContext(UserUpdateContext);
}

function userReducer(user, action) {
  switch (action.type) {
    case 'login': {
      if(action.user) {
          return {
              id: action.user.id,
              name: action.user.name,
              email: action.user.email
          };
      }
      return null;
    }
    case 'logout': {
      return null;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};